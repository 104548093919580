<template>
  <div :class="{ isMobile }">
    <el-container class="preview" v-loading="loading">
      <el-header>
        <h3>{{ title }}</h3>
        <p>{{ time }}</p>
      </el-header>
      <el-main v-html="content"></el-main>
    </el-container>
  </div>
</template>

<script>
import { getArticleList } from '@/api/public'
import { parseTime } from '@/utils'

export default {
  name: 'Preview',
  data() {
    return {
      time: null,
      title: null,
      content: null,
      loading: true,
      dialogVisible: false
    }
  },
  methods: {
    load() {
      getArticleList({ id: this.$route.query.id })
        .then((resp) => {
          const { time, title, content } = resp.data
          this.time = parseTime(time * 1000)
          this.title = title
          this.content = content
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped>
.preview {
  width: 800px;
  text-align: left;
  margin: 0 auto;
}

.isMobile .preview {
  width: 100%;
}
</style>
